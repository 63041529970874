body
  margin: 0
  padding: 0

.img-flex
  display: grid
  height: 100%
  width: 100%
  // grid-tempate-columns: repeat(auto-fill, minmax(250px, 50%) ) 
  grid-template-columns: repeat(auto-fill, minmax(200px, 50%)) // minmax(100px, 50%)) 
  // grid-template-rows: repeat(auto-fill,  minmax(1fr, 50%)) // minmax(10px, 50%))
  grid-auto-rows: 1fr
  grid-auto-columns: 1fr

  @media screen and (min-width: 645px)
    grid-template-columns: repeat(auto-fill, minmax(200px, 25%)) // minmax(100px, 50%)) 

    @media screen and (min-height: 400px)
      grid-template-columns: repeat(auto-fill, minmax(200px, 33.33%)) // minmax(100px, 50%)) 

  @media screen and (min-width: 976px)
    grid-template-columns: repeat(auto-fill, minmax(200px, 25%)) // minmax(100px, 50%)) 
  .img

    box-sizing: border-box
    flex-basis: 1
    background-origin: center
    background-size: 160%
    transition: background 0.3s

    &:hover
      background-position: 20% 20%
      background-size: 200%

    &.one
      background-image: url('imgs/pic-1.jpg')
    &.two
      background-image: url('imgs/pic-2.jpg')
    &.three
      background-image: url('imgs/pic-3.jpg')
    &.four
      background-image: url('imgs/pic-4.jpg')
    &.five
      background-image: url('imgs/pic-5.jpg')
    &.six
      background-image: url('imgs/pic-6.jpg')
    &.seven
      background-image: url('imgs/pic-7.jpg')
    &.eight
      background-image: url('imgs/pic-8.jpg')


#loading-screen
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  background-color: rgba(0, 0, 0, 0.6)

  p
    font-family: Verdana, Geneva, Tahoma, sans-serif
    margin-top: 20%
    font-size: 50px
    text-align: center
    color: white